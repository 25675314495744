<template>
  <div class="wg-card-more wg-card-model">
    <div class="wg-card-more">
      <router-link
        to="/search"
        class="wg-card-more-link"
      >
        <img class="wg-card-more-background" :src="background" alt="background">
        <div class="wg-card-more-text">
          <div class="wg-card-more-title">{{ $t($props.card.title) }}</div>
          <div class="wg-card-more-bold">{{ $t($props.card.description) }}</div>
          <div class="wg-card-more-numbers">
            <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="3.5" cy="3.5" r="3.5" fill="white"/>
            </svg>
            {{ siteSettings?.global_online || 0 }}
          </div>
          <div class="wg-card-more-online">
            {{ $t($props.card.online) }}
          </div>
        </div>
      </router-link>
    </div>
    <router-link
      to="/search"
      class="wg-card-more-button-link"
    >
      <WGButton
        class="wg-card-more-button text-green-700"
        :outline="true"
      >
        {{ $t($props.card.button) }}
      </WGButton>
    </router-link>
  </div>
</template>

<script>
import background from '@/assets/images/sliderGirlsList/showMoreBg.png'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'WGSliderShowMore',
  components: { WGButton },
  props: {
    card: Object
  },
  setup () {
    const store = useStore()
    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })

    return {
      background,
      siteSettings
    }
  }
}
</script>

<style lang="scss">
.wg-card-more {
  &-button {
    margin: 12px 0 0 0 !important;
    width: 100%;
    max-height: 34px;
    height: 100%;
    font-size: 14px;
    padding: 9px 0;
  }
  &-link {
    border-radius: 4px 8px 4px 4px;
    overflow: hidden;
    display: block;
    position: relative;
  }
  &-background {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 230px;
    max-height: 230px;
    @media screen and (max-width: 1024px) {
      min-height: 188px;
      max-height: 188px;
    }
    @media screen and (max-width: 768px) {
      min-height: 216px;
      max-height: 216px;
    }
    @media screen and (max-width: 375px) {
      min-height: 223px;
      max-height: 223px;
    }
  }
  &-button-link {
    display: block;
    height: 100%;
    width: 99%;
  }
  &-text {
    position: absolute;
    color: white;
    left: 50%;
    top: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
  }
  &-title {
    font-size: 20px;
  }
  &-bold {
    font-weight: 700;
    font-size: 20px;
  }
  &-numbers {
    font-size: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &-online {
    font-size: 16px;
  }

  @media screen and (max-width: 375px) {
    &-title {
      font-size: 16px;
    }
    &-bold {
      font-weight: 700;
      font-size: 16px;
    }
    &-numbers {
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &-online {
      font-size: 12px;
    }
  }
}
</style>
