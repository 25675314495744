<template>

  <main class="page-main">
    <!--    <WGIndicatorStatuses />-->
    <WGContainer isClearOffsets>
      <WGTopBar :class="{'wg-top-bar--promotion': isActivePromotion}" title="sliderMain.topBar">
        {{ currentProjectLink }}
      </WGTopBar>
      <WGSliderMain v-if="!isShowMobileBackground" />
      <div v-else class="wg-page-home__mobile-background">
<!--        <div class="wg-page-home__mobile-background_image"-->
<!--             :style="`background-image: linear-gradient(rgba(1, 97, 189, 0.8), rgba(1, 97, 189, 0)), url(${staticMobileBackground});`">-->
<!--        </div>-->
        <div class="wg-page-home__mobile-background_image"></div>
        <WGAuthForm class="wg-page-home__mobile-background_form" />
      </div>
    </WGContainer>

    <div class="wg-page-home-advantages-holder">
       <WGSwiper
        class="wg-page-home-advantages-holder--mobile"
        :swiperList="mobileAdvantages"
        :autoHeight="true"
        :spaceBetween="24"
        :slidesPerView="4"
        :slidesPerGroup="1"
        :centeredSlides="false"
        :speed="1000"
        :loop="true"
        :slideToClickedSlide="true"
        :breakpoints="{
          320: {
          slidesPerView: 4,
          spaceBetween: 10,
          },
          550: {
          slidesPerView: 4,
          spaceBetween: 10,
          },
        }"
        >
          <template #item="{ item }">
            <router-link
              to="/search"
              class="wg-link"
              @click="$emit('click')"
            >
              <WGCardAdvantages :advantage="item">
                <template v-slot="{ titleAdds }">
                  {{ setAdvantageValue(titleAdds) }}
                </template>
              </WGCardAdvantages>
            </router-link>
          </template>
      </WGSwiper>
    </div>
    <WGContainer class="bg-white wg-page-home-container">

      <div class="wg-page-home-content">

        <!-- TODO: need to create a preloader )) Sirko / D.D. -->
        <WGGrid
          :dataList="advantages"
          class="wg-grid-single-girls wg-page-home-content-advantages--desktop"
          :columns="4"
          :columnsMd="2"
          :columnsSm="2"
          :columnsXs="1"
          :gap="24"
          :gapXs="16"
        >
          <template #item="{ item }">
            <router-link
              to="/search"
              class="wg-link wg-page-home-content_advantages-link"
              @click="$emit('click')"
            >
              <WGCardAdvantages
                :advantage="item"
              >
                <template v-slot="{ titleAdds }">
                  <div class="wg-card-advantages_title">
                    {{ setAdvantageValue(titleAdds) }}
                  </div>
                </template>
              </WGCardAdvantages>
            </router-link>
          </template>
        </WGGrid>

        <WGTitleMarkLetter
          class="wg-slider-user-reviews-section-2"
          :title="$t('global.top50girls')"
        >

          <template #slotActions>
            <WGSwiperExternalNavigation :swiperName="sliderGirls" />
          </template>

          <template #default>
            <WGSwiper
              v-if="allGirlsList"
              :swiperList="allGirlsList"
              :spaceBetween="24"
              :breakpoints="{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 8,
                },
                470: {
                  slidesPerView: 3,
                  spaceBetween: 24
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 24,
                },
                1024 : {
                  slidesPerView: 6,
                },
              }"
              @swiper="onSwiperGirls"
            >
              <template #item="{item}">
                <div class="wg-slider-main_twice-column">
                  <WGCardModel
                    :user="item.itemUp"
                    :urlStorage="siteSettings?.settings?.url_storage || null"
                  />
                  <WGCardModel
                    v-if="item.itemDown.id !== 'catalog'"
                    :user="item.itemDown"
                    :urlStorage="siteSettings?.settings?.url_storage || null"
                  />
                  <WGSliderShowMore v-else :card="item.itemDown" />
                </div>
              </template>
            </WGSwiper>
            <WGPreloader v-else />
          </template>
        </WGTitleMarkLetter>

        <div class="wg-page-home-information">
          <WGTitleMarkLetter>
            <template #slotTitle>
              <h2 class="wg-page-home-information_title">
                {{ $t('siteTextInformation.mainText') }}
              </h2>
            </template>
          </WGTitleMarkLetter>
          <h3 class="wg-page-home-information_paragraph-headline-3">{{ $t('siteTextInformation.subtitle') }}</h3>
          <div>
            <p
              v-for="key in 1"
              :key="key"
              class="wg-page-home-information_paragraph"
            >
              {{ paragraphText(key) }}
              <span
                v-if="isHiddenText && key === 1"
                @click="isHiddenText = !isHiddenText"
                class="wg-page-home-information_paragraph-option"
              >
              {{ $t('global.showMore') }}
              <img src="@/assets/images/icons/blue-shevron-down.svg" alt="shevron-down">
            </span>
            </p>
          </div>
          <p v-show="!isHiddenText" class="wg-page-home-information_paragraph">
            {{ $t('siteTextInformation.paragraph2') }}
          </p>
          <div v-show="!isHiddenText" class="wg-page-home-information--hidden">
            <ul class="wg-page-home-information_wrapper">
              <li
                v-for="key in 5"
                :key="key"
                class="wg-page-home-information_list"
              >
                <span class="wg-page-home-information_list--bold">{{ listTitleText(key) }}</span>&nbsp;{{ listText(key) }}
              </li>
            </ul>
            <h3 class="wg-page-home-information_paragraph-headline-3">{{ $t('siteTextInformation.subtitle2') }}</h3>
            <p class="wg-page-home-information_paragraph--last">
              {{ $t('siteTextInformation.paragraph3') }}
            </p>
          </div>
          <p
            v-show="!isHiddenText"
            @click="isHiddenText = !isHiddenText"
            class="wg-page-home-information_paragraph-option wg-page-home-information_paragraph-option--closed"
          >
            {{ $t('global.showLess') }}
            <img
              class="wg-page-home-information_paragraph-option_arrow"
              src="@/assets/images/icons/blue-shevron-down.svg"
              alt="shevron-down"
            />
          </p>
          <WGBanner
            :background="bannerBackground"
            background-color="rgba(1, 97, 189, 0.85)"
            shadow-text="bannerMainPage.mainTitle"
          >
            <template #contentStart>
              <div class="wg-page-home_banner-content">
                <div class="wg-page-home_banner-content_image">
                  <img class="wg-page-home_banner-content_image-item" src="@/assets/images/logo-full-white.png" alt="logo-short"/>
                </div>
              </div>
            </template>
            <template #contentEnd>
              <div class="wg-page-home_banner-content_end">
                <img :src="familyPhoto" alt="family-photo"/>
              </div>
            </template>
          </WGBanner>
        </div>

        <WGTitleMarkLetter
          v-if="!isShowMobileBackground"
          class="wg-slider-user-reviews-section-2"
          :title="$t('index.titleMark5')"
        >

          <template #slotActions>
            <WGSwiperExternalNavigation :swiperName="sliderStories" />
          </template>

          <template #default>

            <WGSwiper
              class="wg-slider-user-reviews"
              :swiperList="successStoriesList"
              :autoHeight="true"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true
              }"
              :loop="true"
              :spaceBetween="24"
              :breakpoints="{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                  loop: true
                },
                575: {
                  slidesPerView: 'auto',
                  spaceBetween: 24,
                  loop: true
                },
                1024 : {
                  slidesPerView: 2,
                },
              }"
              @swiper="onSwiperStories"
            >
              <template #item="{item}">

                <WGCardBlockquote
                  :user="item"
                  isLargeImg
                  isQuoteRevers
                />

              </template>
            </WGSwiper>

          </template>

        </WGTitleMarkLetter>

        <WGTitleMarkLetter
          v-if="ourBlogs"
          class="wg-slider-user-reviews-section-2"
          :title="$t('global.ourBlog')"
        >
          <template #default>
            <WGGrid
              :dataList="Object.values(ourBlogs)"
              :columns="2"
              :columnsMd="2"
              :columnsSm="1"
              :columnsXs="1"
              :gap="24"
              :gapXs="16"
            >
              <template #item="{item, index}">
                <WGLink :linkHref="`/about-online-dating${item.url}`">
                  <template #default>
                    <div class="wg-blog-item">
                      <img :src="siteSettings?.settings?.url_storage+item.src" :alt="item.alt ?? ''">
                      <div class="wg-blog-item_title" :class="{'wg-blog-item_title--left': (index+1)%2}">
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </WGLink>
              </template>
            </WGGrid>
          </template>
        </WGTitleMarkLetter>

        <WGTitleMarkLetter
          class="wg-slider-user-reviews-section-2"
          :title="$t('global.testimonials')"
        >

          <template #slotActions>
            <WGSwiperExternalNavigation :swiperName="sliderTestimonials" />
          </template>

          <template #default>

            <WGSwiper
              class="wg-slider-user-reviews"
              :swiperList="testimonialsList"
              :autoHeight="true"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true
              }"
              :pagination="{
                clickable: true,
              }"
              :loop="true"
              :spaceBetween="24"
              :breakpoints="{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                  loop: true
                },
                575: {
                  slidesPerView: 'auto',
                  spaceBetween: 24,
                  loop: true
                },
                1024 : {
                  slidesPerView: 2,
                },
              }"
              @swiper="onSwiperTestimonials"
            >
              <template #item="{item}">

                <WGCardBlockquote
                  :user="item"
                >

                  <template #slotHeadActions v-if="item.actions">
                    <WGLink
                      type="button"
                      variant="main-secondary"
                      linkIcon="wg-ffi-video-play-fill"
                      linkIconDirection="right"
                      linkIconClass="text-green-700"
                      isBold
                      size="sm"
                    >
                      {{ $t('global.watchVideo') }}
                      <br>
                      {{ $t('global.testimonials').toLowerCase() }}
                    </WGLink>
                  </template>

                  <template #slotQuote>

                    <WGReadMore @onClickShowMore="updateSliderTestimonials">
                      <template #slotText>
                        {{ $t(item.user_quote, { website: currentProjectLink }) }}
                      </template>
                    </WGReadMore>

                  </template>

                </WGCardBlockquote>

              </template>
            </WGSwiper>

          </template>

        </WGTitleMarkLetter>

      </div>

    </WGContainer>
  </main>

</template>
<script>

import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  STORIES_LIST,
  TESTIMONIALS_LIST,
  SUCCESS_STORIES_LIST,
  ADVANTAGES_LIST,
  ADVANTAGES_LIST_MOBILE
} from '@/data/userReviewsData'
import { useBreakpointStore } from '@/controllers/components/breakpointMixin'

import WGSwiper from '@/components/WGSliders/WGSwiper.vue'

import WGSliderMain from '@/components/WGSliders/WGSliderMain.vue'
import WGSwiperExternalNavigation from '@/components/WGSliders/WGSwiperExternalNavigation.vue'

import bannerBackground from '@/assets/images/bannerMainPage/girls-list-bg.webp'
import familyPhoto from '@/assets/images/bannerMainPage/family-photo.webp'

import WGGrid from '@/components/UI/WGGrids/WGGrid.vue'
import WGLink from '@/components/UI/WGLinks/WGLink.vue'
import WGAuthForm from '@/components/auth/WGAuthForm.vue'
import WGTopBar from '@/components/UI/WGTopBar/WGTopBar.vue'
import WGCardModel from '@/components/UI/WGCards/WGCardModel.vue'
import WGReadMore from '@/components/UI/WGTypography/WGReadMore.vue'
import WGPreloader from '@/components/UI/WGPreloader/WGPreloader.vue'
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGCardAdvantages from '@/components/UI/WGCards/WGCardAdvantages.vue'
import WGCardBlockquote from '@/components/UI/WGCards/WGCardBlockquote.vue'
import WGTitleMarkLetter from '@/components/UI/WGTitleMarkLetter/WGTitleMarkLetter.vue'
import WGBanner from '@/components/UI/WGBanners/WGBanner.vue'
import WGSliderShowMore from '@/components/UI/WGCards/WGSlideShowMore.vue'
export default defineComponent({
  name: 'PageHome',
  components: {
    WGSliderShowMore,
    WGAuthForm,
    WGBanner,
    WGCardAdvantages,
    WGTopBar,
    WGCardModel,
    WGContainer,
    WGSliderMain,
    WGGrid,
    WGLink,
    WGTitleMarkLetter,
    WGSwiper,
    WGCardBlockquote,
    WGReadMore,
    WGSwiperExternalNavigation,
    WGPreloader
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const { currentBreakpoint, getCurrentBreakpoint } = useBreakpointStore()
    const { currentProjectLink } = inject('$projectLinks')
    const advantages = ADVANTAGES_LIST
    const mobileAdvantages = ADVANTAGES_LIST_MOBILE
    const storiesList = STORIES_LIST
    const testimonialsList = TESTIMONIALS_LIST
    const successStoriesList = SUCCESS_STORIES_LIST
    const lastSlideCard = {
      id: 'catalog',
      title: 'index.seeAll',
      description: 'index.singleGirls',
      online: 'index.girlsOnline',
      button: 'index.seeAllButton'
    }
    const sliderTestimonials = ref(null)
    const sliderGirls = ref(null)
    const sliderStories = ref(null)
    const isHiddenText = ref(true)
    const paragraphCount = ref(2)

    onMounted(() => {
      getCurrentBreakpoint()
    })

    const paragraphText = key => {
      return t(`siteTextInformation.paragraph${key}`)
    }
    // eslint-disable-next-line no-undef
    function gtag () { dataLayer.push(arguments) }
    const sendEventToGtag = () => {
      gtag('event', 'main_page_model_click')
    }

    const isActivePromotion = computed(() => {
      return store.getters.getSiteSettings?.promo_action?.active
    })

    const isShowMobileBackground = computed(() => {
      return ['XXS', 'XS', 'SM', 'MD'].includes(currentBreakpoint.value)
    })

    const updateMetaDescriptionAndTitle = () => {
      document.title = `Best Dating Site for Men - We Can Help You Find Your Love | ${currentProjectLink}`
      const newDescription = 'Interracial Dating Website Single Slavic, Ukrainian, Hungarian, Russian, and other beautiful ladies online on our website. Looking for your princess? We are here to help!'
      const metaDescriptionTag = document.querySelector('meta[name="description"]')
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', newDescription)
      } else {
        const newMetaTag = document.createElement('meta')
        newMetaTag.setAttribute('name', 'description')
        newMetaTag.setAttribute('content', newDescription)
        document.head.appendChild(newMetaTag)
      }
    }
    updateMetaDescriptionAndTitle()

    const listText = key => {
      return t(`siteTextInformation.list${key}`)
    }

    const listTitleText = key => {
      return t(`siteTextInformation.list${key}title`)
    }

    const onSwiperTestimonials = (swiper) => {
      sliderTestimonials.value = swiper
    }

    const onSwiperGirls = swiper => {
      sliderGirls.value = swiper
    }

    const updateSliderTestimonials = () => {
      setTimeout(() => {
        sliderTestimonials.value.updateAutoHeight(true)
        sliderTestimonials.value.update(true)
      }, 50)
    }

    const updateSliderGirls = () => {
      setTimeout(() => {
        sliderGirls.value.updateAutoHeight(true)
        sliderGirls.value.update(true)
      }, 50)
    }

    const onSwiperStories = (swiper) => {
      sliderStories.value = swiper
    }

    const getServerData = () => {
      if (!isShowMobileBackground.value) {
        store.dispatch('apiGetGirlsList', { limit: 23 })

        if (store.hasModule('blog')) {
          store.dispatch('apiGetOurBlog')
        }
      }
    }

    getServerData()

    watch(() => isShowMobileBackground.value, () => {
      getServerData()
    })

    const ourBlogs = computed(() => {
      if (store.hasModule('blog')) {
        return store.state.blog.ourBlog
      }
      return null
    })

    const allGirlsList = computed(() => {
      let girlsList = store.getters.getGirlsList('all')
      if (!girlsList) {
        return []
      }

      if (Array.isArray(girlsList)) {
        girlsList = {
          list: [
            ...girlsList
          ]
        }
      }

      if (!Array.isArray(girlsList.list)) {
        girlsList = {
          list: [
            ...girlsList.list.data
          ]
        }
      }
      girlsList.list.push(lastSlideCard)

      return [...girlsList.list].reduce((accumulator, currentItem, index) => {
        if (index < Math.floor(girlsList.list.length / 2)) {
          accumulator.push({ itemUp: currentItem, itemDown: null })
        } else {
          const currentIndex = index - Math.floor(girlsList.list.length / 2)
          if (accumulator[currentIndex]) {
            accumulator[currentIndex].itemDown = currentItem
          }
        }
        return accumulator
      }, [])
    })

    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })

    const setAdvantageValue = advantage => {
      switch (true) {
        case [0, 5].includes(advantage.id): {
          return siteSettings.value?.global_users
        }
        case [2, 6].includes(advantage.id): {
          return new Date().getFullYear() - advantage.yearFound
        }

        default: return ''
      }
    }

    return {
      mobileAdvantages,
      isShowMobileBackground,
      allGirlsList,
      siteSettings,
      testimonialsList,
      bannerBackground,
      lastSlideCard,
      storiesList,
      successStoriesList,
      sliderTestimonials,
      ourBlogs,
      advantages,
      sliderStories,
      familyPhoto,
      sliderGirls,
      isHiddenText,
      paragraphCount,
      currentProjectLink,
      isActivePromotion,
      sendEventToGtag,
      listText,
      listTitleText,
      setAdvantageValue,
      paragraphText,
      onSwiperTestimonials,
      onSwiperGirls,
      updateSliderTestimonials,
      updateSliderGirls,
      onSwiperStories
    }
  }
})
</script>
