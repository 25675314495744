<template>

  <div class="wg-slider-main-wrap">

    <Swiper
      class="wg-slider-main"
      :modules="modules"
      :thumbs="{ swiper: thumbsSwiperSettings }"
      effect="fade"
      :autoplay="{
        delay: swiperSlideTimeout,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }"
      ref="sliderMain"
      @slideChange="onChangeSwiperMain"
      @swiper="onSwiperMain"
      @beforeSlideChangeStart="onBeforeChangeSwiperMain"
    >

      <template #wrapper-start>

        <div class="swiper-progressbar-box">
          <div
            class="swiper-progressbar"
            :class="{ 'active': isActive }"
            :style="isActive ? `transition-duration: ${swiperSlideTimeout/1000}s;`: ''"
          >
          </div>
        </div>

      </template>

      <template #container-start>
        <WGAuthForm />
      </template>

      <SwiperSlide v-for="item in sliderData" :key="item">

        <div
          class="wg-slider-main-box"
          :class="item.className"
        >

          <img
            class="wg-slider-main-box_background"
            :src="item.imgSrc"
            alt="desktop-background"
            loading="lazy"
          >
          <figcaption class="wg-slider-main-desc">

            <div class="wg-main-general-title" v-if="item.generalTitle">
              {{ item.generalTitle }}
            </div>

            <div class="wg-main-title" v-if="item.mainTitle">
              {{ item.mainTitle }}
            </div>

            <div class="wg-main-subtitle" v-if="item.mainSubtitle">
              {{ item.mainSubtitle }}
            </div>

            <div class="wg-main-text" v-if="item.mainText">
              {{ item.mainText }}
            </div>

            <div class="wg-main-subtext" v-if="item.mainSubtext">
              {{ item.mainSubtext }}
            </div>

            <WGListDefinition :list="item.listDefinition"/>

            <WGList :list="item.list"/>

          </figcaption>

        </div>

      </SwiperSlide>

    </Swiper>

    <Swiper
      class="wg-slider-main-thumbs"
      :slidesPerView="'auto'"
      :spaceBetween="0"
      :watchSlidesProgress="true"
      :modules="modules"
      ref="sliderThumbs"
      @swiper="onSwiperThumbs"
      v-show="isShowThumbsSlider"
    >

      <SwiperSlide v-for="slide in sliderData" :key="slide">
        <div class="wg-slider-main-thumb">
          <!-- TODO: Create a component for picture D.D. -->
          <picture class="wg-slider-main-thumb-img">
            <source :srcset="slide.imgSrcSmall">
            <img :src="slide.imgSrcSmall" alt="thumb-img">
          </picture>
        </div>
      </SwiperSlide>

    </Swiper>

  </div>

</template>

<script>

import { onMounted, ref } from 'vue'

import { useI18n } from 'vue-i18n'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { EffectFade, Thumbs, Autoplay } from 'swiper'

import { SITE_URL } from '@/settings/links'

import WGAuthForm from '@/components/auth/WGAuthForm'
import WGList from '@/components/UI/WGLists/WGList'
import WGListDefinition from '@/components/UI/WGLists/WGListDefinition'

// eslint-disable-next-line no-unused-vars
import { XS, SM, MD, LG, XXS } from '@/components/UI/breakpoints.js'

export default {
  name: 'WGSliderMain',
  components: {
    Swiper,
    SwiperSlide,
    WGAuthForm,
    WGList,
    WGListDefinition
  },
  setup () {
    const { t } = useI18n()

    const siteUrl = SITE_URL

    const isMini = ref(false)

    const swiperSlideTimeout = ref(5000)

    const sliderMain = ref(null)

    const sliderThumbs = ref(null)

    const isActive = ref(false)

    const isShowThumbsSlider = ref(true)

    const thumbsSwiperSettings = ref(null)

    const sliderData = ref([
      {
        imgSrc: require('@/assets/images/mainSlider/img-1.webp'),
        imgSrcSmall: require('@/assets/images/mainSlider/img-1xs.webp'),
        mainTitle: t('sliderMain.slide1.mainTitle'),
        mainSubtitle: t('sliderMain.slide1.mainSubtitle'),
        mainText: t('sliderMain.slide1.mainText'),
        mainSubtext: 'VictoriyaClub',
        className: 'wg-slider-main-box-0'
      },
      {
        imgSrc: require('@/assets/images/mainSlider/img-3.webp'),
        imgSrcSmall: require('@/assets/images/mainSlider/img-3xs.webp'),
        generalTitle: t('sliderMain.slide3.generalTitle'),
        mainTitle: t('sliderMain.slide3.mainTitle'),
        mainSubtitle: t('sliderMain.slide3.mainSubtitle'),
        mainText: t('sliderMain.slide3.mainText'),
        mainSubtext: t('sliderMain.slide3.mainSubtext'),
        className: 'wg-slider-main-box-2'
      },
      {
        imgSrc: require('@/assets/images/mainSlider/img-4.webp'),
        imgSrcSmall: require('@/assets/images/mainSlider/img-4xs.webp'),
        generalTitle: t('sliderMain.slide4.generalTitle'),
        mainTitle: t('sliderMain.slide4.mainTitle'),
        mainSubtitle: t('sliderMain.slide4.mainSubtitle'),
        mainText: t('sliderMain.slide4.mainText'),
        mainSubtext: t('sliderMain.slide4.mainSubtext'),
        className: 'wg-slider-main-box-3'
      },
      {
        imgSrc: require('@/assets/images/mainSlider/img-5.webp'),
        imgSrcSmall: require('@/assets/images/mainSlider/img-5xs.webp'),
        mainTitle: t('sliderMain.slide5.mainTitle'),
        mainSubtitle: t('sliderMain.slide5.mainSubtitle'),
        mainText: t('sliderMain.slide5.mainText'),
        mainSubtext: t('sliderMain.slide5.mainSubtext'),
        className: 'wg-slider-main-box-4'
      },
      {
        imgSrc: require('@/assets/images/mainSlider/img-6.webp'),
        imgSrcSmall: require('@/assets/images/mainSlider/img-6xs.webp'),
        mainTitle: t('sliderMain.slide6.mainTitle'),
        mainSubtitle: t('sliderMain.slide6.mainSubtitle'),
        mainText: t('sliderMain.slide6.mainText'),
        mainSubtext: t('sliderMain.slide6.mainSubtext'),
        className: 'wg-slider-main-box-5'
      },
      {
        imgSrc: require('@/assets/images/mainSlider/img-7.webp'),
        imgSrcSmall: require('@/assets/images/mainSlider/img-7xs.webp'),
        mainTitle: t('sliderMain.slide7.mainTitle'),
        mainSubtitle: t('sliderMain.slide7.mainSubtitle'),
        className: 'wg-slider-main-box-6',
        listDefinition: [
          {
            dt: '$4.88',
            dd: t('sliderMain.slide7.listDefinition.definitionDesc1')
          },
          {
            dt: '$0.35',
            dd: t('sliderMain.slide7.listDefinition.definitionDesc2')
          }
        ],
        list: [
          t('sliderMain.slide7.list.listItem1'),
          t('sliderMain.slide7.list.listItem2'),
          t('sliderMain.slide7.list.listItem3')
        ]
      },
      {
        imgSrc: require('@/assets/images/mainSlider/img-2.webp'),
        imgSrcSmall: require('@/assets/images/mainSlider/img-2xs.webp'),
        mainTitle: t('sliderMain.slide2.mainTitle'),
        mainSubtitle: t('sliderMain.slide2.mainSubtitle'),
        mainText: t('sliderMain.slide2.mainText'),
        mainSubtext: t('sliderMain.slide2.mainSubtext'),
        className: 'wg-slider-main-box-1'
      }
    ])

    const onSwiperMain = (swiper) => {
      if (isShowThumbsSlider.value) {
        sliderMain.value = swiper
      }
      isActive.value = true
    }

    const onChangeSwiperMain = () => {
      isActive.value = false
      setTimeout(() => {
        isActive.value = true
      }, 100)
    }

    const onBeforeChangeSwiperMain = () => {
      isActive.value = false
    }

    const onSwiperThumbs = (swiper) => {
      thumbsSwiperSettings.value = swiper

      sliderThumbs.value = swiper
    }

    const onChangeBreakpoints = () => {
      const breakpointMD = window.matchMedia('(max-width:' + MD + 'px)')
      const breakpointSM = window.matchMedia('(max-width:' + SM + 'px)')

      /*
        XS = 576
        SM = 767
        MD = 1023
        LG = 1200
      */

      isMini.value = !breakpointMD.matches

      breakpointMD.onchange = (e) => {
        isMini.value = !e.matches
      }

      isShowThumbsSlider.value = !breakpointSM.matches

      breakpointSM.onchange = (e) => {
        isShowThumbsSlider.value = !e.matches
      }
    }

    onMounted(() => {
      onChangeBreakpoints()
    })

    return {
      modules: [EffectFade, Thumbs, Autoplay],
      siteUrl,
      sliderMain,
      isMini,
      isActive,
      swiperSlideTimeout,
      sliderData,
      thumbsSwiperSettings,
      isShowThumbsSlider,
      onChangeSwiperMain,
      onSwiperMain,
      onSwiperThumbs,
      onBeforeChangeSwiperMain
    }
  }
}
</script>
