<template>
  <div class="wg-banner" :style="`background-image: url('${background}')`">
    <div class="wg-banner_content-start" :style="`background:${backgroundColor}`">
      <div class="wg-banner_content-start_headline">
        <h2 class="wg-banner_content-start_headline-item">
          {{ $t('bannerMainPage.mainTitleText') }}<br>{{ $t('bannerMainPage.mainTitleText2') }}
        </h2>
        <router-link :to="'/registration'">
          <WGButton
            variant="warning"
            size="sm"
            class="wg-banner_content-start_headline-button text-uppercase"
          >
            {{ $t('bannerMainPage.button') }}
          </WGButton>
        </router-link>
      </div>
      <div v-if="$slots.contentStart" class="wg-banner_content-start_describe">
        <slot name="contentStart"></slot>
      </div>
      <div v-else class="wg-banner_content-start_describe">
        {{ $t(textContent) }}
      </div>
    </div>
    <div class="wg-banner_content-end" :style="`background:${backgroundColor}`">
    </div>
    <div class="wg-banner_content-start_headline-shadow">
      {{ $t(shadowText) }}
    </div>
    <div class="wg-banner_content-start_headline wg-banner_content-start_headline--mobile">
      <h2 class="wg-banner_content-start_headline-item">
        {{ $t('bannerMainPage.mainTitleText') }}<br>{{ $t('bannerMainPage.mainTitleText2') }}
      </h2>
      <router-link
        :to="'/registration'"
      >
        <WGButton
          variant="warning"
          size="sm"
          class="wg-banner_content-start_headline-button text-uppercase"
        >
          {{ $t('bannerMainPage.button') }}
        </WGButton>
      </router-link>
    </div>
    <slot name="contentEnd"></slot>
  </div>
</template>

<script>
import WGButton from '@/components/UI/WGButtons/WGButton.vue'

export default {
  name: 'WGBanner',
  components: {
    WGButton
  },
  props: {
    shadowText: {
      required: true,
      type: String
    },
    textContent: {
      type: String,
      default: ''
    },
    backgroundColor: String,
    background: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/victoria/UI/baner-main-page.scss";
</style>
